export const ALL_APPS = [
  "approve",
  "roi",
  "dashboard",
  "jetforce",
  "tag-generator",
  "predict",
  "estimate",
  "appshop",
  "foilshop",
  "alphajet-viewer",
  "guidelines",
  "docapprove",
  "docdashboard",
  "register",
];

export function getAvailableApps(userApps: string[]): APPS_TYPE[] {
  userApps = userApps
    .sort((u) => {
      // TODO : sort with a range of app
      if (u === "register") return -1;
      else return 0;
    })
    .sort((v) => {
      if (v === "predict") return -1;
      else return 0;
    });

  return userApps.filter(
    (app) => ALL_APPS.includes(app) && !USER_APPS_EXTERNAL.includes(app)
  );
}
export function getUserApps(userApps: string[]): APPS_TYPE[] {
  return userApps.filter((app) => USER_APPS_EXTERNAL.includes(app)).sort();
}
export function getResourceApps(availableApps: string[]): APPS_TYPE[] {
  const docs = [];

  if (availableApps.includes("dashboard")) {
    docs.push("docdashboard");
  }
  if (availableApps.includes("predict")) {
    docs.push("docpredict");
  }
  if (availableApps.includes("approve")) {
    docs.push("docapprove", "guidelines");
  }

  return docs;
}

export type APPS_TYPE = (typeof ALL_APPS)[number];

export const USER_APPS_EXTERNAL: APPS_TYPE[] = [
  "approve",
  "dashboard",
  "jetforce",
];

export interface AppsInfos {
  name: string;
  logo: string;
  color: string;
  link: string;
}

export {};
