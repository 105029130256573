import React from "react";
import classNames from "classnames";
import styles from "./styles/Chooseequipment.module.scss";
import { useTranslation } from "react-i18next";
// import plus from "../img/icons/plus.svg";
import { KeyedMutator } from "swr";
import { Machine } from "./Domain/machine.interface";
import { mapMachineToImage } from "@mgi-labs/lib-liste-machine";

const Chooseequipment = ({
  machines,
  mutate,
  setIsVisibleEquipment,
}: // setIsVisibleModifyEquipment,
// theme,
{
  machines: Machine[] | undefined;
  mutate: KeyedMutator<Machine[]>;
  setIsVisibleEquipment: React.Dispatch<boolean>;
  // setIsVisibleModifyEquipment: React.Dispatch<boolean>;
  // theme: "dark" | "light";
}) => {
  const { t } = useTranslation();

  // const backend = useBackend();

  // const handleDissociateEquipment = useCallback(
  //   async (id: number) => {
  //     if (window.confirm(t("dissociateEquiment"))) {
  //       try {
  //         await backend.dissociateEquipment(id);
  //       } finally {
  //         mutate();
  //       }
  //     }
  //   },
  //   [backend, mutate, t]
  // );

  return (
    <div className={classNames(styles.chooseequipment, styles.ht3, "col-3")}>
      <div className={classNames(styles.containerzone)}>
        <div className={classNames(styles.equipmenttitle)}>
          <p>{t("myequipments")}</p>
          <div className={classNames(styles.equipmentaction)}>
            {/* <div
              className={classNames(styles.oneaction)}
              style={{ visibility: selectedMachine ? "visible" : "hidden" }}
              onClick={() => setIsVisibleModifyEquipment(true)}
              data-title={t("modifyequipment")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  className={classNames(styles.fill)}
                  d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
                />
              </svg>
            </div>
            <div
              className={classNames(styles.oneaction)}
              data-title={t("deleteequipment")}
              style={{ visibility: selectedMachine ? "visible" : "hidden" }}
              // onClick={() =>
              //   // selectedMachine && handleDissociateEquipment(selectedMachine)
              // }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
              >
                <g id="delete_forever" opacity="0.805">
                  <path
                    id="Tracé_1474"
                    data-name="Tracé 1474"
                    d="M0,0H26V26H0Z"
                    fill="none"
                  />
                  <path
                    id="Tracé_1475"
                    data-name="Tracé 1475"
                    d="M0,0H26V26H0Z"
                    fill="none"
                  />
                  <path
                    className={classNames(styles.fill)}
                    id="Tracé_1476"
                    data-name="Tracé 1476"
                    d="M6.083,20.333A2.173,2.173,0,0,0,8.25,22.5h8.667a2.173,2.173,0,0,0,2.167-2.167v-13h-13ZM8.748,12.62l1.528-1.528,2.307,2.3,2.3-2.3,1.528,1.528-2.3,2.3,2.3,2.3L14.88,18.741l-2.3-2.3-2.3,2.3L8.759,17.213l2.3-2.3Zm7.627-8.537L15.292,3H9.875L8.792,4.083H5V6.25H20.167V4.083Z"
                    transform="translate(0.417 0.25)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div> */}

            <div
              className={classNames(styles.oneaction)}
              data-title={t("addequipment")}
            >
              <svg
                onClick={() => setIsVisibleEquipment(true)}
                xmlns="http://www.w3.org/2000/svg"
                width="26.5"
                height="26.5"
                viewBox="0 0 26.5 26.5"
              >
                <g
                  id="add_circle"
                  transform="translate(0.25 0.25)"
                  opacity="0.797"
                >
                  <path
                    className={classNames(styles.strokenone)}
                    id="Tracé_1479"
                    data-name="Tracé 1479"
                    d="M0,0H26V26H0Z"
                    fill="none"
                    stroke="#0f1011"
                    strokeWidth="0.5"
                  />
                  <path
                    className={classNames(styles.fill)}
                    id="Tracé_1480"
                    data-name="Tracé 1480"
                    d="M13.917,7.417H11.75V11.75H7.417v2.167H11.75V18.25h2.167V13.917H18.25V11.75H13.917ZM12.833,2A10.833,10.833,0,1,0,23.667,12.833,10.837,10.837,0,0,0,12.833,2Zm0,19.5A8.667,8.667,0,1,1,21.5,12.833,8.678,8.678,0,0,1,12.833,21.5Z"
                    transform="translate(0.167 0.167)"
                    fill="#fff"
                    stroke="#0f1011"
                    strokeWidth="0.5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className={classNames(styles.allequipments)}>
          {machines && machines.length > 0 ? (
            <>
              {machines?.map((m) => (
                <div
                  title={m.name + "/" + m.serialNumber}
                  key={m.id}
                  className={classNames(styles.oneequipment)}
                  // onClick={() => {
                  //   if (selectedMachine === m.id) {
                  //     setSelectedMachine(undefined);
                  //   } else setSelectedMachine(m.id);
                  // }}
                >
                  <img src={mapMachineToImage(m.type)} alt={m.type} />
                  <p>
                    {m.name} / {m.serialNumber}
                  </p>
                </div>
              ))}
            </>
          ) : (
            <div
              onClick={() => setIsVisibleEquipment(true)}
              className={classNames(styles.oneequipment, styles.newequipment)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29.089"
                height="29.089"
                viewBox="0 0 29.089 29.089"
              >
                <path
                  id="Tracé_94"
                  data-name="Tracé 94"
                  d="M17.724,9.147H14.865v5.718H9.147v2.859h5.718v5.718h2.859V17.724h5.718V14.865H17.724ZM16.295,2A14.295,14.295,0,1,0,30.589,16.295,14.3,14.3,0,0,0,16.295,2Zm0,25.73A11.436,11.436,0,1,1,27.73,16.295,11.451,11.451,0,0,1,16.295,27.73Z"
                  transform="translate(-1.75 -1.75)"
                  fill="#fff"
                  stroke="#064bbb"
                  strokeWidth="0.5"
                />
              </svg>

              <p>{t("addequipment")}</p>
            </div>
          )}
        </div>
        <div className={classNames(styles.equipmentfinalize)}>
          <p>
            {t("tofinalizeactivation")}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.5"
                height="26.5"
                viewBox="0 0 26.5 26.5"
              >
                <g
                  id="add_circle"
                  transform="translate(0.25 0.25)"
                  opacity="0.797"
                >
                  <path
                    id="Tracé_1480"
                    data-name="Tracé 1480"
                    d="M13.917,7.417H11.75V11.75H7.417v2.167H11.75V18.25h2.167V13.917H18.25V11.75H13.917ZM12.833,2A10.833,10.833,0,1,0,23.667,12.833,10.837,10.837,0,0,0,12.833,2Zm0,19.5A8.667,8.667,0,1,1,21.5,12.833,8.678,8.678,0,0,1,12.833,21.5Z"
                    transform="translate(0.167 0.167)"
                    fill="#fff"
                    stroke="#0f1011"
                    stroke-width="0.5"
                  ></path>
                </g>
              </svg>
            </span>
            {t("tofinalizeactivation2")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Chooseequipment;
