import classNames from "classnames";
import styles from "./styles/Applications.module.scss";
import openbtn from "../images/icon/openbtn.svg";
import { useAuth } from "@mgi-labs/mgi-id";
import { AppsInfos } from "./Domain/AvailableApps";
import add from "../images/icon/add.svg";
import people from "../images/icon/people.svg";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";
import { UseGroup } from "../hooks/UseGroup";
import { NavLink } from "react-router-dom";

const OneApp = ({ app }: { app: AppsInfos }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();

  const backend = useBackend();
  const { data: groupUsers } = UseGroup();

  return (
    <>
      <div
        className={classNames(
          styles.padding,
          styles.ht1,
          styles.float_left,
          styles.ht1sm,

          "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
        )}
      >
        <a
          href={app.link}
          target={!auth.authenticated ? "self" : "_blank"}
          rel="noreferrer"
          className={classNames(styles.tile, styles.textcolor, app.color)}
        >
          <div className={classNames(styles.light)}></div>

          {auth.authenticated && (
            <div className={classNames(styles.topbar, "col-12")}>
              <p>{"App"}</p>
            </div>
          )}
          <div className={classNames(styles.content, "col-12")}>
            <div className={classNames(styles.dispflex)}>
              <img src={app.logo} alt="Approve logo" />
            </div>
          </div>
          <div className={classNames(styles.bottomzone, "col-12")}>
            <p>{app.name}</p>
            <div className={classNames(styles.open, "col-6")}>
              <p>{auth.authenticated ? t("Ouvrir") : t("Decouvrir")}</p>
              <img src={openbtn} alt="openbtn" />
            </div>
          </div>
        </a>
        <div className={classNames(styles.userzone, "col-12")}>
          {backend.limitation.packType !== "no" &&
            backend.limitation.packType !== "beta" && (
              <NavLink to="/extensions">
                <div className={classNames(styles.add_user)}>
                  {app.name !== "Jet force" && (
                    <>
                      <img src={add} alt="+" />
                      <p>{t("Extensions")}</p>
                    </>
                  )}
                </div>
              </NavLink>
            )}
          <div className={classNames(styles.add_user)}>
            <img src={people} alt="O" />
            <p>
              {groupUsers?.length} / {backend.limitation.user}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OneApp;
